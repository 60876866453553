import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Avatar,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BsClockHistory } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    padding: "0 !important",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
  Follow: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#FFFFFF",
    width: "100px",
    background: " #0D8CCD",
  },
}));

export default function UsersCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data, user, callbackFn } = props;

  const classes = useStyles();
  let isFollow = false;
  if (user?.userData) {
    const resArr = data?.followers?.filter(
      (data) => data === user?.userData?._id
    );
    isFollow = resArr?.length > 0;
  }
  return (
    <Box className="CardBox following_cardBox">
      <Box className="User_card following_card">
        <Box className={classes.bottomblock}>
          {" "}
          <figure class="user_img following">
            <img
              className="rounded-circle"
              src={data.profilePic ? data.profilePic : "images/users/1.png"}
              alt=""
            />
            {data.accountVerify && (
              <img src={data.check} className="check_icon2" />
            )}
          </figure>
          <Box>
            <Typography variant="h5"> {data.name}</Typography>
            <Typography variant="body2">
              {data.followersCount
                ? data.followersCount
                : data.followingCount
                ? data.followingCount
                : 0}
            </Typography>
          </Box>
        </Box>
        <Button
          onClick={() => {
            if (callbackFn) {
              callbackFn(data._id);
            }
          }}
          disabled={!user.isLogin}
          variant="contained"
          size="small"
          className={classes.Follow}
        >
          {isFollow ? "Unfollow" : "Follow"}
        </Button>
      </Box>
    </Box>
  );
}

import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import { FiFacebook } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link as RouterLink } from "react-router-dom";
import {} from "react-feather";
import Logo from "./../../component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#0D8CCD",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: "130px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    "& p": {
      marginBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  input_fild: {
    backgroundColor: "#ffffff6e",
    borderRadius: "10px",
    color: "#fff",
    height: "48px",
    width: "100%",
    borderBottom: "none",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: 0,
    },
  },

  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#0F8ACA",
    borderRadius: "10px",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "48px",
    minWidth: "135px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#fff",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "&:hover": {
      background: "#fff",
      color: "#0F8ACA",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Box style={{ margin: "20px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ color: "white" }}
              justify="space-around"
              spacing={1}
            >
              <img
                src="images/footer-bg.png"
                alt=""
                className={classes.footerBg}
              />
              <Grid item xs={12} md={12} lg={7}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} md={12}>
                    <Typography variant="h5">follow us</Typography>
                    <List class="media_links">
                      <ListItem>
                        <Link
                          href="https://www.facebook.com/"
                          target="_blank"
                          color="secondary"
                        >
                          <FiFacebook />
                        </Link>
                        <Link
                          href="https://in.linkedin.com/"
                          color="secondary"
                          target="_blank"
                        >
                          <FiLinkedin />
                        </Link>
                        <Link
                          href="https://www.instagram.com/fungynft/"
                          color="secondary"
                          target="_blank"
                        >
                          <InstagramIcon />
                        </Link>
                        <Link
                          href="https://www.youtube.com/channel/"
                          color="secondary"
                          target="_blank"
                        >
                          <YouTubeIcon />
                        </Link>

                        <Link
                          href="https://twitter.com/fungynft"
                          color="secondary"
                          target="_blank"
                        >
                          <FiTwitter />
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="h5">Quick Links</Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Explore
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          component={RouterLink}
                          color="secondary"
                          to="/faq"
                        >
                          How it works
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Support
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Become a partner
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="h5">Community</Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          RARI Token
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Discussion
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Voting
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Suggest feature
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h5"> &nbsp; </Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link component={RouterLink} color="secondary" to="/">
                          Help Center
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          component={RouterLink}
                          color="secondary"
                          to="/terms-conditions"
                        >
                          Terms and Conditions
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          component={RouterLink}
                          color="secondary"
                          to="/privacy-policy"
                        >
                          Privacy Policy
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <Typography variant="h5">Stay in the loop</Typography>
                <Typography variant="body1" component="p">
                  Join our mailing list to stay in the loop with our newest
                  feature releases, NFT drops, and tips and tricks for
                  navigating fungy.
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      placeholder="Your email address"
                      variant="outlined"
                      className={classes.input_fild}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Button className={classes.signupBtn}>Sign up</Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h5">keep in touch</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      placeholder="Your name"
                      variant="outlined"
                      className={classes.input_fild}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      placeholder="E-mail"
                      variant="outlined"
                      className={classes.input_fild}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      placeholder="Leave your message|"
                      variant="outlined"
                      className={classes.input_fild}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Button className={classes.signupBtn} fullWidth>
                      SEND <FiArrowRight />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import Sale from "./Sale";
import Owned from "./Owned";
import Activity from "./Activity";
import Created from "./Created";
import Liked from "./Liked";
import Following from "src/component/Following";
import { Link as RouterLink } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ImShare2 } from "react-icons/im";
import { FiMoreHorizontal } from "react-icons/fi";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { SiFacebook } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import DialogActions from "@material-ui/core/DialogActions";
import { GiCancel } from "react-icons/gi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { Tooltip } from "@material-ui/core";
import {
  Grid,
  Box,
  IconButton,
  Container,
  Typography,
  makeStyles,
  Button,
  Link,
} from "@material-ui/core";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { useLocation } from "react-router-dom";
import PageLoading from "src/component/PageLoading";
import { sortAddress } from "src/utils";
// import { SocialLinkBox } from '../Profile/Profile';
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";
import TwitterIcon from "@material-ui/icons/Twitter";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  paper: {
    overflowY: "unset",
  },
  wallet: {
    background: "#f4f4f4",
    paddingTop: "68px",
    paddingBottom: theme.spacing(6),
  },
  modalFollow: {
    "& .MuiDialog-paperWidthSm": {
      width: "300px",
    },
  },
  sectionTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
  },
  sectionTitleHead2: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  metamask: {
    maxWidth: "100%",
    width: "400px",
  },
  selectOption: {
    width: "300px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justifyContent: "space-around",
  },
  walletSet: {
    padding: "0",
  },
  qrCoce: {
    maxWidth: "100%",
    width: "250px",
  },

  copyQr: {
    maxWidth: "250px",
    display: "flex",
  },
  padding0: {
    padding: "0",
  },
  walletDetails: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  FollowingBox: {
    height: "300px",
    overflowx: "scroll",
  },
  edit_icon: {
    position: "absolute",
    height: "28px",
    maxWidth: "28px",
    top: "0",
    right: "0",
    display: "flex",
  },
}));

function myFunction() {
  var copyText = document.getElementById("myInput");
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export default function UsersView() {
  const user = useContext(UserContext);
  const classes = useStyles();
  const [tabview, setTabView] = useState("sale");
  const location = useLocation();
  const [followingOpen, setFollowingOpen] = useState(false);
  const [followersOpen, setFollowersOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [onSaleList, setonSaleList] = useState([]);
  const [OwnedList, setOwnedList] = useState([]);
  const [createdList, setCreatedList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [likesList, setLikesList] = useState([]);
  const [userID, setUserID] = useState("");
  const [allListPageNumber, setAllListPageNumber] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [soldNFTList, setSoldNFTList] = useState([]);
  const [maxPages, setMaxPages] = useState(1);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const handleClose = () => {
    setFollowersOpen(false);
  };

  const getProfile = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.profileUser}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setUserProfile(res.data.result);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const getOnSaleDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userOnSaleCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setonSaleList(res.data.result.docs);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getOwenedDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userOwendCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setOwnedList(res.data.result.docs);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getCreatedDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userCreatedCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setCreatedList(res.data.result.docs);
        } else {
          setCreatedList([]);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getActivityDetails = async (id, isLoadmore, cancelTokenSource) => {
    let page = isLoadmore ? allListPageNumber + 1 : allListPageNumber;
    setAllListPageNumber(page);
    let allList = [];
    for (let i = 1; i <= page; i++) {
      try {
        const res = await axios.get(`${apiConfig.showActivity}`, {
          params: {
            _id: id,
            page: i,
            limit: 10,
          },
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.data.statusCode === 200) {
          if (res.data.result.docs) {
            setMaxPages(res.data.result.pages);
            allList = await allList.concat(res.data.result.docs);
            const filterData = allList.filter(
              (data) => data.nftId || data.followingUserId
            );
            setActivityList(filterData);
            setDataToDisplay(filterData);
          } else {
            setActivityList([]);
          }
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    }
  };

  const getFollowingDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userFollowingCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setFollowingList(res.data.result.following);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getFollowersDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userFollowerCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setFollowersList(res.data.result.followers);
        if (user.userData) {
          const resArr = res.data.result.followers.filter(
            (data) => data.followersId._id === user.userData._id
          );
          setIsFollowing(resArr.length > 0 ? true : false);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getSoldItemListDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.nftSoldCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setSoldNFTList(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getLikesDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userLikesCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          const result = res.data.result.docs.filter((data) => data.orderId);
          setLikesList(result);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (selectedFilter === "All") {
      setDataToDisplay(activityList);
    }
    if (selectedFilter === "Listing") {
      const result = activityList.filter((data) => data.type === "NEWNFT");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Likes") {
      const result = activityList.filter(
        (data) => data.type === "DISLIKE" || data.type === "LIKE"
      );
      setDataToDisplay(result);
    }
    if (selectedFilter === "Bids") {
      const result = activityList.filter((data) => data.type === "BIDPLACE");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Sold") {
      const result = activityList.filter((data) => data.type === "BIDPLACE");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Purchases") {
      const result = activityList.filter((data) => data.type === "SALEORDER");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Followings") {
      const result = activityList.filter(
        (data) => data.type === "UNFOLLOW" || data.type === "FOLLOW"
      );
      setDataToDisplay(result);
    }
  }, [selectedFilter, activityList]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (location.search && location.search.slice(1, location.search.length)) {
      const id = location.search.slice(1, location.search.length);
      setUserID(id);
      getProfile(id, cancelTokenSource);
      getOnSaleDetails(id, cancelTokenSource);
      getSoldItemListDetails(id, cancelTokenSource);
      getOwenedDetails(id, cancelTokenSource);
      getCreatedDetails(id, cancelTokenSource);
      getFollowingDetails(id, cancelTokenSource);
      getFollowersDetails(id, cancelTokenSource);
      getLikesDetails(id, cancelTokenSource);
      getActivityDetails(id, false, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [location, user.userData]);

  const updateAllDetails = async () => {
    if (userID) {
      getProfile(userID);
      getOnSaleDetails(userID);
      getSoldItemListDetails(userID);
      getOwenedDetails(userID);
      getCreatedDetails(userID);
      getFollowingDetails(userID);
      getFollowersDetails(userID);
      getLikesDetails(userID);
      getActivityDetails(userID);
    }
  };

  const followUnfollowDandler = async (id) => {
    if (user.isLogin) {
      try {
        const token = sessionStorage.getItem("token");
        const res = await axios.post(
          apiConfig.following,
          {
            userId: id,
          },
          {
            headers: {
              token,
            },
          }
        );
        console.log("res", res);
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);

          if (user.userData) {
            const resArr = res.data.result.following.filter(
              (data) => data.followingId === id
            );
            setIsFollowing(resArr.length > 0 ? true : false);
            user.getProfileHandler(token);
          }
          getFollowingDetails(userID);
          getFollowersDetails(userID);
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.log("ERROR", error);
        toast.error(error.message);
      }
    } else {
      alert("Please login");
    }
  };
  return (
    <>
      {isLoading && !userProfile ? (
        <PageLoading />
      ) : (
        <Box className={classes.wallet}>
          {" "}
          <Box className="usersView">
            <Container maxWidth="lg">
              <figure className="figure">
                <img
                  src={
                    userProfile?.bannerImage
                      ? userProfile?.bannerImage
                      : "/images/Profilebg.png"
                  }
                  className="img_responsive"
                  alt=""
                  style={{ height: 300 }}
                />
                <Box className="share_Box">
                  <Box>
                    <Box
                      className={
                        isActive ? "toggel_box show" : "toggel_box hide"
                      }
                    >
                      Share link to this page
                      <SocialLinkBox url={window.location} />{" "}
                    </Box>
                    <Button className="share-btn" onClick={toggleClass}>
                      <ImShare2 />
                    </Button>
                  </Box>
                  {user.userData && user.userData._id === userID ? (
                    ""
                  ) : (
                    <Box>
                      <Box
                        className={
                          isActive1 ? "toggel_box show" : "toggel_box hide"
                        }
                      >
                        Report Page
                      </Box>
                      <Button className="share-btn" onClick={toggleClass1}>
                        <FiMoreHorizontal />
                      </Button>
                    </Box>
                  )}
                </Box>
              </figure>
            </Container>
            <Container maxWidth="lg">
              <Box className="usersPic">
                <figure>
                  <img
                    src={
                      userProfile?.profilePic
                        ? userProfile?.profilePic
                        : "/images/users/5.png"
                    }
                    className="img_responsive "
                    alt=""
                  />

                  {userProfile?.accountVerify && (
                    <img
                      src="images/collections/check.png"
                      className="check_icon"
                    />
                  )}
                </figure>
                <Box className="userDetails" textAlign="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {userProfile && userProfile.name && (
                      <Tooltip title="UserName" placement="top">
                        <Typography
                          variant="h4"
                          className="SectionTitle m-b-5"
                          style={{ marginTop: "15px" }}
                        >
                          {userProfile?.name}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                  <Box className="userDetails" textAlign="center">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mb={4}
                    >
                      <Link
                        href="https://twitter.com/fungynft"
                        target="_blank"
                        style={{ display: "flex", textDecoration: "none" }}
                      >
                        <TwitterIcon
                          style={{ color: "#0d8ccd", alignItems: "center" }}
                        />
                        <Typography variant="h6">
                          https://twitter.com/farhan
                        </Typography>
                      </Link>
                      <input
                        id={"myInput"}
                        value={
                          userProfile &&
                          userProfile.walletAddress &&
                          userProfile.walletAddress
                        }
                        style={{ display: "none" }}
                      />
                      <Tooltip title="Address" placement="top">
                        <Typography variant="h5" className="tokenAddress">
                          {sortAddress(userProfile?.walletAddress)}&nbsp;
                          <CopyToClipboard
                            text={userProfile?.walletAddress}
                            style={{ color: "black", fontSize: "15px" }}
                          >
                            <FiCopy onClick={() => myFunction()} />
                          </CopyToClipboard>{" "}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  {user.userData && user.userData._id === userID ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to="/edit-profile"
                    >
                      Edit Profile
                    </Button>
                  ) : (
                    <Button
                      onClick={() => followUnfollowDandler(userID)}
                      variant="contained"
                      color="primary"
                    >
                      {isFollowing ? "Unfollow" : "Follow"}
                    </Button>
                  )}
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ marginTop: 20 }}
                  >
                    {userProfile && userProfile?.bio}
                  </Typography>
                  <Box className="socialLink" mt={4}>
                    {userProfile && userProfile?.customUrl && (
                      <a href={userProfile.customUrl} target="_blank">
                        <img src="images/website.png" />
                        <Typography variant="body">
                          {userProfile?.customUrl}
                        </Typography>
                      </a>
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
          <Box mt={4} mb={2}>
            <Container maxWidth="lg">
              <Box className="TabButtonsBox">
                <Button
                  className={tabview === "sale" ? "active" : " "}
                  onClick={() => setTabView("sale")}
                >
                  On sale <span>{onSaleList.length}</span>
                </Button>
                <Button
                  className={tabview === "Owned" ? "active" : " "}
                  onClick={() => setTabView("Owned")}
                >
                  Owned <span>{OwnedList.length}</span>
                </Button>
                <Button
                  className={tabview === "Sold" ? "active" : " "}
                  onClick={() => setTabView("Sold")}
                >
                  Sold <span>{soldNFTList.length}</span>
                </Button>
                <Button
                  className={tabview === "Created" ? "active" : " "}
                  onClick={() => setTabView("Created")}
                >
                  Created <span>{createdList.length}</span>
                </Button>
                <Button
                  className={tabview === "Liked" ? "active" : " "}
                  onClick={() => setTabView("Liked")}
                >
                  Liked <span>{likesList.length}</span>
                </Button>
                <Button
                  className={tabview === "Activity" ? "active" : " "}
                  onClick={() => setTabView("Activity")}
                >
                  Activity{" "}
                </Button>
                <Button onClick={() => setFollowingOpen(true)}>
                  Following <span>{followingList.length}</span>
                </Button>
                <Button onClick={() => setFollowersOpen(true)}>
                  Followers <span>{followersList.length}</span>
                </Button>
              </Box>
              <Box className="TabButtonsContant">
                {tabview === "sale" ? (
                  <Sale
                    onSaleList={onSaleList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Owned" ? (
                  <Owned
                    OwnedList={OwnedList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Sold" ? (
                  <Owned
                    OwnedList={soldNFTList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Created" ? (
                  <Created
                    createdList={createdList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Liked" ? (
                  <Liked
                    likesList={likesList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Activity" ? (
                  <Activity
                    activityList={dataToDisplay}
                    userID={userID}
                    callBackFun={(id, status) => getActivityDetails(id, status)}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={(data) => setSelectedFilter(data)}
                    allListPageNumber={allListPageNumber}
                    maxPages={maxPages}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Container>
          </Box>
          {followingOpen && (
            <Dialog
              open={followingOpen}
              maxWidth="sm"
              onClose={() => setFollowingOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.paper }}
              className={classes.modalFollow}
            >
              <DialogActions>
                <IconButton
                  onClick={() => setFollowingOpen(false)}
                  className={classes.customizedButton}
                >
                  <GiCancel />
                </IconButton>
              </DialogActions>
              <DialogContent className={classes.padding0}>
                <Box align="center" mb={5}>
                  <img src="images/Following.svg" />
                  <Typography variant="h5">Following</Typography>
                </Box>
                <Box className={classes.FollowingBox}>
                  <Grid container>
                    {followingList.map((data, i) => {
                      let reqRes = [];
                      if (user.userData) {
                        reqRes = user.userData.following.filter(
                          (userD) => userD.followingId === data.followingId._id
                        );
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          key={i}
                          className="walletSet "
                        >
                          <Following
                            data={data.followingId}
                            type="timing"
                            index={i}
                            user={user}
                            callbackFn={(id) => followUnfollowDandler(id)}
                            // isFollow={reqRes.length > 0 ? false : true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          )}
          {followersOpen && (
            <Dialog
              open={followersOpen}
              maxWidth="sm"
              onClose={() => setFollowersOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.paper }}
              className={classes.modalFollow}
            >
              <DialogActions>
                <IconButton
                  onClick={() => setFollowersOpen(false)}
                  className={classes.customizedButton}
                >
                  <GiCancel />
                </IconButton>
              </DialogActions>
              <DialogContent className={classes.padding0}>
                <Box align="center" mb={5}>
                  <img src="images/Following.svg" />
                  <Typography variant="h5">Followers</Typography>
                </Box>
                {followersList && followersList.length === 0 ? (
                  <Box>
                    <Grid
                      container
                      style={{
                        marginLeft: "35px",
                        color: "#0d8ccd",
                        marginBottom: "20px",
                      }}
                    >
                      You don't have any follower
                    </Grid>
                  </Box>
                ) : (
                  <Box className={classes.FollowingBox}>
                    <Grid container>
                      {followersList.map((data, i) => {
                        let reqRes = [];
                        if (user.userData) {
                          reqRes = user.userData.followers.filter(
                            (userD) =>
                              userD.followingId === data.followersId._id
                          );
                        }
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            key={i}
                            className="walletSet "
                          >
                            <Following
                              data={data.followersId}
                              type="timing"
                              index={i}
                              user={user}
                              callbackFn={(id) => followUnfollowDandler(id)}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
              </DialogContent>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}

export const SocialLinkBox = ({ url, props }) => {
  const classes = useStyles();
  return (
    <List>
      <ListItem>
        <label>
          <FacebookShareButton
            url={url}
            quote={props?.data?.nftId?.tokenName}
            hashtag={props?.data?.nftId?.tokenName}
          >
            <SiFacebook />
          </FacebookShareButton>
        </label>
        <Typography variant="h6">Facebook</Typography>
      </ListItem>
      <ListItem>
        <label>
          <TwitterShareButton
            url={url}
            quote={"CampersTribe - World is yours to explore"}
            hashtag={props?.data?.nftId?.tokenName}
          >
            <FaTwitter />
          </TwitterShareButton>
        </label>
        <Typography variant="h6">Twitter</Typography>
      </ListItem>
      <ListItem>
        <label>
          <TelegramShareButton
            url={url}
            title={"CampersTribe - World is yours to explore"}
            hashtag={props?.data?.nftId?.tokenName}
          >
            <FaTelegramPlane />
          </TelegramShareButton>
        </label>
        <Typography variant="h6">Telegram</Typography>
      </ListItem>
      <ListItem>
        <label>
          <EmailShareButton
            url={url}
            subject="frontend"
            body="frontend frontend"
            hashtag={props?.data?.nftId?.tokenName}
          >
            <GrMail />
          </EmailShareButton>
        </label>
        <Typography variant="h6">E-mail</Typography>
      </ListItem>
    </List>
  );
};

import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React, useSWR } from "@web3-react/core";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import Web3 from "web3";
import moment from "moment";
import { socketURL } from "src/config/ApiConfig";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [allCollection, setAllCollection] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allNftList, setAllNftList] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [auctionList, setAuctionList] = useState([]);
  const [allBidList, setAllBidList] = useState([]);
  const [hotBidList, setHotBidList] = useState([]);
  const [liveEthPrice, setLiveEthPrice] = useState();
  const [serviceFee, setServiceFee] = useState(0);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [hotCollectionList, setHotCollectionList] = useState([]);
  const [allListPageNumber, setAllListPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [promotedNftList, setPromotedNftList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [errorPop, setErrorPop] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [categoryNavList, setCategoryNavList] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [searchItemDetails, setSearchItemDetails] = useState();
  const connectToWallet = (data) => {
    if (data) {
      const connector = data.data.connector;
      window.sessionStorage.removeItem("walletName");
      window.sessionStorage.setItem("walletName", data.name);
      setErrorMsg("");
      setSuccessMSG("");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          console.log("error", error.message);
          setErrorMsg(error.message);
          activate(connector);
          setIsLoading(false);
          setErrorPop(true);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
      setUserData();
    }
  }, [account]);

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectToWallet(selectectWalletDetails[0]);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.statusCode === 200) {
        getProfileHandler(res.data.result.token);
        setTokenSession(res.data.result.token);
        setSuccessMSG(res.data.responseMessage);
      } else {
        deactivate();
        setIsLogin(false);
        setUserData();
        setIsLoading(false);
        setIsLogin(true);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  const promotedListHandelr = async () => {
    try {
      const res = await axios.get(apiConfig.listPromoteOrderUser, {
        params: {
          limit: 100,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setPromotedNftList(res.data.result.docs);
        } else {
          setPromotedNftList([]);
        }
      } else {
        setPromotedNftList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.profile, {
        headers: {
          token,
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  const getPlaceOrderList = async (isLoadmore) => {
    let page = isLoadmore ? allListPageNumber + 1 : allListPageNumber;
    setAllListPageNumber(page);
    let allList = [];
    for (let i = 1; i <= page; i++) {
      try {
        const res = await axios.get(apiConfig.allOrderList, {
          params: {
            page: i,
          },
        });
        if (res.data.statusCode === 200) {
          if (res.data.result.docs) {
            setMaxPages(res.data.result.pages);
            allList = await allList.concat(res.data.result.docs);
            console.log("allList", allList);

            const result = allList.filter(
              (data) => data.price || parseFloat(data.startPrice)
            );
            const acuList = result.filter((data) => {
              return (
                (data.orderType === "TIMED_AUCTION" ||
                  data.orderType === "AUCTION_FIXEDPRICE_BOTH") &&
                parseFloat(data.endTime) > moment().unix()
              );
            });
            setAllOrders(result);
            setAuctionList(acuList);
          } else {
            setAllOrders([]);
            setAuctionList([]);
          }
        } else {
          setAllOrders([]);
          setAuctionList([]);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const allNftListHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.allNftList);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllNftList(res.data.result.docs);
        } else {
          setAllNftList([]);
        }
      } else {
        setAllNftList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getuserList = async () => {
    try {
      const res = await axios.get(apiConfig.userList, {});
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllUserList(res.data.result.docs);
        } else {
          setAllUserList([]);
        }
      } else {
        setAllUserList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getlistCollection = async () => {
    try {
      const res = await axios.get(apiConfig.listCollection);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllCollection(res.data.result.docs);
        } else {
          setAllCollection([]);
        }
      } else {
        setAllCollection([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getallBidList = async () => {
    try {
      const res = await axios.get(apiConfig.allbidList);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllBidList(res.data.result.docs);
        } else {
          setAllBidList([]);
        }
      } else {
        setAllBidList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const hotBidListHandler = async () => {
    try {
      const res = await axios.get(apiConfig.hotBidList);
      if (res.data.statusCode === 200) {
        setHotBidList(res.data.result);
      } else {
        setHotBidList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const hotCollectionListHandler = async () => {
    try {
      const res = await axios.get(apiConfig.hotCollectionList);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setHotCollectionList(res.data.result.docs);
        } else {
          setHotCollectionList();
        }
      } else {
        setHotCollectionList();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLiveEthPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
      );
      console.log("getLiveEthPrice", res);
      if (res.status === 200) {
        setLiveEthPrice(res.data.ethereum.usd);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getServiceFees = async () => {
    try {
      const res = await axios.get(apiConfig.viewFeesUser);

      console.log("getServiceFees", res);
      if (res.data.statusCode === 200) {
        setServiceFee(parseFloat(res.data.result.feesInPercentage));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getListCategory = async () => {
    try {
      const res = await axios.get(apiConfig.listCategory);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllCategory(res.data.result.docs);
          let categoryNavListL = [];
          for (let i = 0; i < res.data.result.docs.length; i++) {
            categoryNavListL.push({
              title: res.data.result.docs[i].categoryTitle,
              icon: res.data.result.docs[i].categoryIcon
                ? res.data.result.docs[i].categoryIcon
                : "icon-palette",
              // icon: FaUserCircle,
              href: `/category-list?${res.data.result.docs[i].categoryTitle}`,
              isImage: true,
            });
          }
          setCategoryNavList(categoryNavListL);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    updateData();
    allNftListHandler();
    getuserList();
    getLiveEthPrice();
    hotCollectionListHandler();
    getServiceFees();
    promotedListHandelr();
    getListCategory();
  }, [isUpdateData]);

  const updateData = () => {
    getPlaceOrderList();
    getlistCollection();
    getallBidList();
    hotBidListHandler();
    promotedListHandelr();
  };

  useEffect(() => {
    if (isLogin) {
      getListCategory();
    }
  }, [isLogin]);
  // console.log("adsadadadada");
  useEffect(() => {
    const web = new WebSocket(socketURL);
    const accessToken = sessionStorage.getItem("token");
    console.log("accessToken", accessToken);

    setNotificationLoading(true);
    if (accessToken) {
      try {
        web.onopen = () => {
          const dataToSend = {
            option: "notification",
            token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              if (obj.data && obj.data.length > 0) {
                setNotificationList(obj.data);
                setNotificationLoading(false);
                setUnreadCount(obj.unReadCount);
              } else {
                setNotificationList([]);
                setUnreadCount(0);
                setNotificationLoading(false);
              }
            }
          };
        };
        return () => {
          setNotificationList();
          setUnreadCount(0);
          web.close();
        };
      } catch (err) {}
    }
  }, []);

  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    isLogin,
    userData,
    errorMsg,
    successMSG,
    allCollection,
    allOrders,
    allNftList,
    allUserList,
    auctionList,
    allBidList,
    hotBidList,
    hotCollectionList,
    liveEthPrice,
    serviceFee,
    maxPages,
    allListPageNumber,
    yourWalletBalance,
    promotedNftList,
    isLoading,
    allCategory,
    categoryNavList,
    errorPop,
    unreadCount,
    notificationList,
    notificationLoading,
    searchItemDetails,
    setErrorPop,
    setSearchItemDetails: (data) => setSearchItemDetails(data),
    allNftListHandler: () => allNftListHandler(),
    setIsUpdateData: () => setIsUpdateData(!isUpdateData),
    getlistCollection: () => getlistCollection(),
    getPlaceOrderList: (data) => getPlaceOrderList(data),
    getallBidList: () => getallBidList(),
    getProfileHandler: (token) => getProfileHandler(token),
    hotBidListHandler: () => hotBidListHandler(),
    updateData: () => updateData(),
    logoutHandler: () => {
      setIsLogin(false);
      setUserData();
      setYourWalletBalance(0);
      deactivate();
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
